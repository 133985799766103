import { Action, EQ_ACTIONS, Scope, Subject } from '@/c/auth/permissions';
import { enumFromStringValue } from '@/c/utils/datas';

export function hasPermission(permissions: string[], subject: Subject, action: Action): boolean {
    return permissions.includes(formPermission(subject, action));
}

export function hasPermissions(permissions: string[], required: string[]): boolean {
    const pMap = formMap(permissions);
    const rpMap = formMap(required);

    for (const [subject, subjScopesMap] of Array.from(rpMap.entries())) {
        if (!pMap.has(subject)) {
            return false;
        }

        for (const [scope, actions] of Array.from(subjScopesMap.entries())) {
            for (const action of Array.from(actions.keys())) {
                if (
                    !(
                        (pMap.get(subject)!.has(Scope.ALL) && pMap.get(subject)!.get(Scope.ALL)!.has(action)) ||
                        (pMap.get(subject)!.has(scope) && pMap.get(subject)!.get(scope)!.has(action))
                    )
                ) {
                    return false;
                }
            }
        }
    }

    return true;
}

function formMap(permissions: string[]): Map<Subject, Map<Scope, Set<Action>>> {
    const m: Map<Subject, Map<Scope, Set<Action>>> = new Map();

    for (const permission of permissions) {
        const pp = _parsePermission(permission);
        if (!m.has(pp.subject)) {
            m.set(pp.subject, new Map());
        }
        if (!m.get(pp.subject)!.has(pp.scope)) {
            m.get(pp.subject)!.set(pp.scope, new Set());
        }

        const subjScopesMap = m.get(pp.subject)!;
        const subjScopeActions = subjScopesMap.get(pp.scope)!;

        for (const a of EQ_ACTIONS.has(pp.action) ? EQ_ACTIONS.get(pp.action)! : [pp.action]) {
            subjScopeActions.add(a);
        }
    }

    return m;
}

function _parsePermission(permission: string): {
    action: Action;
    scope: Scope;
    subject: Subject;
} {
    const permissionPattern = /([^:]+):([^:]+):?([^:]*)/g;
    const match = permissionPattern.exec(permission);

    if (match === null) {
        throw new Error(`Cannot parse permission ${permission}`);
    }

    return {
        action: enumFromStringValue(Action, match[1]),
        scope: enumFromStringValue(Scope, match[3].length === 0 ? Scope.ALL : match[2]),
        subject: enumFromStringValue(Subject, match[3].length === 0 ? match[2] : match[3]),
    };
}

export function formPermission(subject: Subject, action: Action, scope: Scope = Scope.ALL): string {
    return `${action}:${scope !== Scope.ALL ? `${scope}:` : ''}${subject}`;
}
