import React from 'react';

import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { croutes } from '@/c/routes';
import useTranslation from '@/c/translations/useTranslation';
import { scopedT } from '@/c/translations/utils';
import { LinkRenderer } from '@/core/linkRenderer';

import { BodyPart } from '../../types';
import { Wrapper } from './BodyPartsPage.style';

interface Props {
    bodyparts: BodyPart[];
    onCreateClick: () => void;
    renderLink: LinkRenderer;
}

export default function BodyPartsPage(p: Props): React.ReactElement<Props> {
    const { t } = useTranslation();
    const nt = scopedT(t, 'bodyparts-page');

    return (
        <Wrapper>
            <Button
                style={{ marginBottom: '1rem' }}
                variant={'contained'}
                onClick={() => {
                    p.onCreateClick();
                }}
            >
                {nt('createBodyPartBtn')}
            </Button>
            <div>
                <Table>
                    <TableHead>
                        <TableCell align={'justify'}>{nt('bodyPart')}</TableCell>
                    </TableHead>
                    <TableBody>
                        {p.bodyparts.map((e, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    {p.renderLink(
                                        {
                                            pathname: croutes.BODYPART,
                                            query: {
                                                id: e.id,
                                            },
                                        },
                                        <span>{e.name}</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Wrapper>
    );
}
