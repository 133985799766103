import { createContext } from 'react';

import { ViewManager } from '../types';

export type ViewManagerContextValue = {
    viewManager: ViewManager;
    setViewManager: React.Dispatch<React.SetStateAction<ViewManager>>;
};

export const ViewManagerContext = createContext<ViewManagerContextValue | undefined>(undefined);
ViewManagerContext.displayName = 'ViewManagerContext';
