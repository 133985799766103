import styled from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
`;

export const RoutinesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${(props) => `${props.theme.utils.spacing('S')}`};
    gap: ${(props) => `${props.theme.utils.spacing('S')}`};
    justify-content: space-between;
`;

export const RoutineWrapper = styled.div`
    flex: 1 0 48%;
    padding: ${(props) => `${props.theme.utils.spacing('S')} ${props.theme.utils.spacing('S')}`};
    box-shadow: 0.2px 0.2px 1px #444444;
    span:first-child {
        display: flex;
        justify-content: space-between;
    }
`;
