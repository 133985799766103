import React, { useContext } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Action, Scope, Subject } from '@/c/auth/permissions';
import useHasPermission from '@/c/auth/useHasPermission';
import { formPermission } from '@/c/auth/utils';
import ConfirmDialog from '@/c/components/molecules/ConfirmDialog/ConfirmDialog';
import useModalContext from '@/c/contexts/Modal/useModalContext';
import { UserContext } from '@/c/contexts/UserContext';
import { croutes } from '@/c/routes';
import useTranslation from '@/c/translations/useTranslation';
import { scopedT } from '@/c/translations/utils';
import { LinkRenderer } from '@/core/linkRenderer';

import { ExerciseModel } from '../../types';
import { Wrapper } from './ExercisesPage.style';

interface Props {
    exercises: ExerciseModel[];
    onCreateClick: VoidFunction;
    renderLink: LinkRenderer;
    onDeleteRequested: (id: string) => void;
}

const T_NAMESPACE = 'exercises-page';

export default function ExercisesPage(p: Props): React.ReactElement<Props> {
    const { t } = useTranslation();
    const nt = scopedT(t, T_NAMESPACE);

    const { data } = useContext(UserContext);

    const hasEditAllPermission = useHasPermission(formPermission(Subject.EXERCISE, Action.UPDATE, Scope.ALL)) ?? false;
    const hasEditSelfPermission =
        useHasPermission(formPermission(Subject.EXERCISE, Action.UPDATE, Scope.SELF)) ?? false;
    const hasDeleteAllPermission =
        useHasPermission(formPermission(Subject.EXERCISE, Action.DELETE, Scope.ALL)) ?? false;
    const hasDeleteSelfPermission =
        useHasPermission(formPermission(Subject.EXERCISE, Action.DELETE, Scope.SELF)) ?? false;

    const exercisesThatCanBeModified = p.exercises.filter((el) =>
        hasEditAllPermission ? true : hasEditSelfPermission && el.fromUserId === data.value?.id
    );

    return (
        <Wrapper>
            <Button
                style={{ marginBottom: '1rem' }}
                variant={'contained'}
                onClick={() => {
                    p.onCreateClick();
                }}
            >
                {nt('createExerciseBtn')}
            </Button>
            <div>
                <Table>
                    <TableHead>
                        <TableCell align={'justify'}>{nt('exercise')}</TableCell>
                        <TableCell align={'justify'}>{nt('date')}</TableCell>
                        <TableCell align={'justify'} />
                    </TableHead>
                    <TableBody>
                        {exercisesThatCanBeModified.map((e, i) => (
                            <RowView
                                key={i}
                                m={e}
                                renderLink={p.renderLink}
                                onDeleteRequested={
                                    hasDeleteAllPermission ||
                                    (hasDeleteSelfPermission && e.fromUserId === data.value?.id)
                                        ? p.onDeleteRequested
                                        : null
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Wrapper>
    );
}

function RowView(p: {
    renderLink: LinkRenderer;
    m: ExerciseModel;
    onDeleteRequested: ((id: string) => void) | null;
}): React.ReactElement {
    const { t } = useTranslation();
    const nt = scopedT(t, T_NAMESPACE);
    const modalContext = useModalContext();

    return (
        <TableRow>
            <TableCell>
                {p.renderLink(
                    {
                        pathname: croutes.EXERCISE,
                        query: {
                            id: p.m.id,
                        },
                    },
                    <span>{p.m.name}</span>
                )}
            </TableCell>
            <TableCell>{new Date(p.m.created_at as string).toUTCString()}</TableCell>
            <TableCell>
                {p.onDeleteRequested === null ? null : (
                    <IconButton
                        onClick={() => {
                            modalContext.showModal(ConfirmDialog, {
                                i18n: {
                                    yes: t('yes'),
                                    no: t('no'),
                                },
                                children: <>{nt('confirmExerciseDeletionDialog.desc')}</>,
                                title: nt('confirmExerciseDeletionDialog.title'),
                                onConfirm: () => p.onDeleteRequested!(p.m.id),
                                invertButtonsColor: true,
                            });
                        }}
                        size="large"
                    >
                        <DeleteIcon color="warning" />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
}
