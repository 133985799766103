import '../wdyr';
import React, { useMemo, useState } from 'react';

import '../c/styles/globals.scss';
import { ThemeProvider as MaterialThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { NextComponentType, NextPageContext } from 'next';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ThemeProvider } from 'styled-components';

import assetsResolver from '@/c/assetsResolver';
import GuestLayout from '@/c/components/templates/GuestLayout/GuestLayout';
import LoggedLayout from '@/c/components/templates/LoggedLayout/LoggedLayout';
import { LayoutProps } from '@/c/components/templates/types';
import ViewManagerProvider from '@/c/components/templates/ViewManager/ViewManagerProvider';
import ModalProvider from '@/c/contexts/Modal/ModalProvider';
import { UserContext } from '@/c/contexts/UserContext';
import { LoadableData } from '@/c/data/types';
import useDidMountEffect from '@/c/hooks/useDidMountEffect';
import { isApiLoggedIn } from '@/c/http/api';
import registerServiceWorker from '@/c/registerServiceWorker';
import { GlobalStyle } from '@/c/styles/global';
import Light from '@/c/styles/themes/Light/Light';
import MaterialUiLight from '@/c/styles/themes/Light/MaterialUiLight';
import { MeUser } from '@/c/types/api';
import { useRouter } from '@/core/router';

import nextI18NextConfig from '../../next-i18next.config.js';

const MyApp = function ({ Component, pageProps }: AppProps) {
    // both for client and server side usage in getRouteSource
    const router = useRouter();
    // @ts-ignore
    global.locale = pageProps._nextI18Next?.initialLocale || router.locale;

    const [PageLayout, pageLayoutProps] = getLayoutSettings(Component);

    const [data, setData] = useState<LoadableData<MeUser, Error | Response>>(LoadableData.loading());
    const contextValue = useMemo(() => ({ data, setData }), [data]);

    useDidMountEffect(() => {
        registerServiceWorker();
    });

    return (
        <>
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
            <GlobalStyle />
            <UserContext.Provider value={contextValue}>
                <ReactQueryConfigProvider
                    config={{
                        queries: {
                            refetchOnWindowFocus: false,
                            refetchOnMount: false,
                            refetchOnReconnect: false,
                            staleTime: Infinity,
                            cacheTime: Infinity,
                            retry: false,
                        },
                    }}
                >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={Light}>
                            <MaterialThemeProvider theme={MaterialUiLight}>
                                <Head>
                                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                                    <link rel="icon" href="/cropped-logo-32x32.png" sizes="32x32" />
                                    <link rel="icon" href="/cropped-logo-192x192.png" sizes="192x192" />
                                    <link rel="apple-touch-icon-precomposed" href="/cropped-logo-180x180.png" />
                                    <link rel="manifest" href={assetsResolver.cAsset('json/manifest.json')} />
                                </Head>
                                <ModalProvider>
                                    <ViewManagerProvider {...pageLayoutProps}>
                                        <PageLayout {...pageLayoutProps}>
                                            <Component {...pageProps} />
                                        </PageLayout>
                                    </ViewManagerProvider>
                                </ModalProvider>
                            </MaterialThemeProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </ReactQueryConfigProvider>
            </UserContext.Provider>
        </>
    );
};

export default appWithTranslation(MyApp, nextI18NextConfig);

function getLayoutSettings(
    Component: NextComponentType<NextPageContext, unknown>
): [React.JSXElementConstructor<LayoutProps>, LayoutProps | undefined] {
    let PageLayout = isApiLoggedIn() ? LoggedLayout : GuestLayout;
    // @ts-ignore
    let pageLayoutProps = Component.getLayoutProps || undefined;

    const maxSearchDepth = 7;
    let wrapped = Component;
    let i = 0;
    do {
        // @ts-ignore
        wrapped = wrapped.WrappedComponent;
        let found = false;
        if (wrapped && Object.prototype.hasOwnProperty.call(wrapped, 'getLayoutProps')) {
            // @ts-ignore
            pageLayoutProps = wrapped.getLayoutProps || undefined;
            found = true;
        }

        if (wrapped && Object.prototype.hasOwnProperty.call(wrapped, 'getLayout')) {
            // @ts-ignore
            PageLayout = wrapped.getLayout || undefined;
            found = true;
        }
        if (found) {
            break;
        }
    } while (wrapped && i++ < maxSearchDepth);

    // @ts-ignore
    return [PageLayout, pageLayoutProps];
}
