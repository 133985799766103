import { TextSelectionItem } from '@/c/components/types';

export const DEFAULT_LOCALE = 'en_US';
export const LOCALES = {
    ALBANIAN: 'sq',
    ENGLISH: 'en_US',
};

export type LOCALE = typeof LOCALES[keyof typeof LOCALES];

const LOCALES_TEXTS: Record<LOCALE, string> = {
    sq: 'Shqip',
    en_US: 'English',
};

export const SUPPORTED_LOCALES: string[] = Object.keys(LOCALES).map((k) => LOCALES[k as keyof typeof LOCALES]);

export function getLocalesSelectionItems(localesTexts?: Record<LOCALE, string>): TextSelectionItem[] {
    const lt = localesTexts ?? LOCALES_TEXTS;

    return Object.keys(LOCALES).reduce<TextSelectionItem[]>((p, lk) => {
        // @ts-ignore
        const locale = LOCALES[lk] as unknown as LOCALE;

        p.push({
            value: locale,
            text: lt[locale],
        });
        return p;
    }, []);
}
