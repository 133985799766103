import React from 'react';

import { TFunction, withTranslation } from 'next-i18next';

import LogoImage from '@/c/components/molecules/LogoImage';
import MenuListItem, { MenuItem } from '@/c/components/molecules/Menu/MenuListItem';
import PageContents from '@/c/components/templates/PageContents/PageContents';
import { LayoutProps } from '@/c/components/templates/types';
import useViewManager from '@/c/components/templates/ViewManager/useViewManager';
import { croutes } from '@/c/routes';
import { scopedT } from '@/c/translations/utils';
import { LProps } from '@/c/types/LProps';
import ForwardedLink from '@/core/Link/ForwardedLink';

import Header from '../../organisms/Header/Header';

const GuestLayout: React.FC<LayoutProps & LProps> = ({ children, t, ...p }) => {
    const headerT = scopedT(t, 'header');

    const { viewManager } = useViewManager();

    return (
        <>
            {viewManager.header && (
                <Header
                    startMenuItems={
                        <ForwardedLink name={croutes.INDEX} className={'c-mi'}>
                            <LogoImage />
                        </ForwardedLink>
                    }
                    fixedMenuItems={getMenuItems(headerT).map((mi, i) => (
                        <MenuListItem key={i} mi={mi} />
                    ))}
                />
            )}
            {viewManager.customMain ? children : <PageContents>{children}</PageContents>}
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer'];
export default withTranslation(tNamespaces)(GuestLayout);

function getMenuItems(t: TFunction): MenuItem[] {
    return [
        {
            name: croutes.LOGIN,
            text: t('login'),
        },
        // {
        //     name: croutes.REGISTER,
        //     text: t('register'),
        // },
    ];
}
