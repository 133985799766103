import React, { useEffect, useMemo, useState } from 'react';

import calculateViewManager from '@/c/components/templates/calculateViewManager';
import { LayoutProps, ViewManager } from '@/c/components/templates/types';
import { ViewManagerContext } from '@/c/components/templates/ViewManager/ViewManagerContext';

const ViewManagerProvider: React.FC<LayoutProps> = ({ children, ...p }) => {
    const [viewManager, setViewManager] = useState<ViewManager>(calculateViewManager(p));

    useEffect(() => {
        setViewManager(calculateViewManager(p));
    }, [p.header]);

    const contextValue = useMemo(() => ({ viewManager, setViewManager }), [viewManager]);

    return <ViewManagerContext.Provider value={contextValue}>{children}</ViewManagerContext.Provider>;
};
export default ViewManagerProvider;
