import Router, { NextRouter as RealNextRouter, useRouter as useNextRouter } from 'next/router';

import { resolveRoute } from '@/c/routes';

type Extra = {
    query?: Record<string, string | number>;
};
type PushReplaceFn = (name: string, extra?: Extra) => Promise<boolean>;

export type NextRouter = RealNextRouter & {
    pushNamed: PushReplaceFn;
};

export function useRouter(): NextRouter {
    const router = useNextRouter();

    // @ts-ignore
    router.pushNamed = (name: string, extra: Extra) => {
        return router.push(
            resolveRoute({
                pathname: name,
                query: extra?.query,
            })
        );
    };

    return router as NextRouter;
}

export default Router;
