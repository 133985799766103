const routes = {
    INDEX: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    PROGRESS: '/progress',
    BODYPARTS: '/bodyparts',
    BODYPART: '/bodyparts/:id',
    BODYPART_EDIT: '/bodyparts/:id/edit',
    BODYPART_CREATE: '/bodyparts/create',
    CATEGORIES: '/categories',
    CATEGORY: '/categories/:id',
    CATEGORY_EDIT: '/categories/:id/edit',
    CATEGORY_CREATE: '/categories/create',
    EXERCISES: '/exercises',
    EXERCISE: '/exercises/:id',
    EXERCISE_EDIT: '/exercises/:id/edit',
    EXERCISE_CREATE: '/exercises/create',
    ROUTINES: '/routines',
    ROUTINE: '/routines/:id',
    ROUTINE_EDIT: '/routines/:id/edit',
    ROUTINE_CREATE: '/routines/create',
    MY_WORKOUTS: '/my-workouts',
    WORKOUT: '/workouts/:id',
    WORKOUT_EDIT: '/workouts/:id/edit',
    WORKOUT_CREATE: '/workouts/create',
    USER_ACCOUNT_SETTINGS: '/user/account',
};

const enRoutes = Object.keys(routes).reduce((previousValue, rk) => {
    const path = routes[rk];
    previousValue[path] = {
        source: path,
        destination: path,
        locale: false,
    };
    return previousValue;
}, {});

function localizedRoute(locale) {
    return (path, destination) => ({
        [destination || path]: {
            source: `/${locale}${path}`,
            destination: destination || path,
            locale: false,
        },
    });
}

/**
 * Order of routes is important, first after generic route add /create, then /:id and so on
 */

const sqRouteMaker = localizedRoute('sq');
const sqRoutes = Object.keys(enRoutes).reduce((previousValue, rk) => {
    return {
        ...previousValue,
        ...sqRouteMaker(enRoutes[rk].source, enRoutes[rk].destination),
    };
}, {});

module.exports = {
    routes: routes,
    rewrites: {
        sq: sqRoutes,
        en_US: enRoutes,
    },
};
