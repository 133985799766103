export enum WeightUnit {
    Kilograms = 'kg',
    Pounds = 'lbs',
}

export enum DistanceUnit {
    Kilometers = 'km',
    Miles = 'mi',
}

export type RoutineUnitsConfig = {
    weight: WeightUnit;
    distance: DistanceUnit;
};

export interface BodyPart {
    id: string;
    name: string;
}

export interface Category {
    id: string;
    name: string;
}

export interface Metric {
    id: string;
    name: string;
}

export interface AppliedMetric {
    metric: Metric;
    value: string | number;
}

export type Video = {
    url: string;
};

export type Image = {
    url: string;
};

export type Description = {
    text?: string;
    video?: Video;
};

export interface ExerciseModel {
    id: string;
    name: string;
    imageUrl?: string;
    description: Description;
    fromUserId: string | null;
    bodyParts: BodyPart[];
    categories: Category[];
    metrics: Metric[];
    created_at?: string;
    _deleted?: boolean;
}

export type Duration = number;

export type SetCompletionInfo = {
    rest: number;
    elapsed?: number;
};

export interface ExerciseSet {
    id: string;
    metrics: AppliedMetric[];
    rest: Duration;
    completionInfo?: SetCompletionInfo;
}

export interface SingleExerciseEntry {
    id: string;
    exercise: ExerciseModel;
    sets: ExerciseSet[];
}

export interface CombinedExerciseEntry {
    id: string;
    exercises: SingleExerciseEntry[];
    circuit?: boolean;
    repetitions: number;
    rest: Duration;
}

export type ExerciseEntry = SingleExerciseEntry | CombinedExerciseEntry;

export enum RoutineEntryType {
    CUSTOM = 'CUSTOM',
    WARMUP = 'WARMUP',
    STRENGTH = 'STRENGTH',
    SKILL = 'SKILL',
    PREHAB = 'PREHAB',
    COOLDOWN = 'COOLDOWN',
    STRETCHING = 'STRETCHING',
}

export interface RoutineEntry {
    id: string;
    type: RoutineEntryType;
    exerciseEntries: ExerciseEntry[];
}

export type ValueSuggester = { forMetric: (m: Metric) => string | number; forRest: () => number };

export type TextSelectionItem = {
    value: string;
    text: string;
};
