export function generateRequestId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function stringToInt(input: string): number {
    const asNr = parseInt(input);
    return isNaN(asNr) ? numericHash(input) : asNr;
}

export function numericHash(input: string): number {
    let hash = 0;
    let i;
    let chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function undefinedsToNulls(input: Record<string, unknown>): Record<string, unknown> {
    return Object.keys(input).reduce((p: Record<string, any>, k) => {
        const v = input[k];
        p[k] = v === undefined ? null : v;
        return p;
    }, {});
}

export function removeUndefined<T extends Record<string, unknown>>(input: Record<string, unknown>): T {
    return Object.keys(input).reduce((p: T, k) => {
        const v = input[k];
        if (v === undefined) {
            return p;
        }
        // @ts-ignore
        p[k] = v;
        return p;
    }, {} as T);
}

export function readAsDataURL(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = (readEvent) => {
            resolve(readEvent.target!.result);
        };
        fr.readAsDataURL(file);
    });
}

export function selectNested<T>(object: Record<string, unknown>, key: string): T {
    const keyParts = key.split('.');
    if (keyParts.length > 1) {
        // @ts-ignore
        return selectNested(object[keyParts[0]], keyParts.splice(1).join('.'));
    }

    return object[keyParts[0]] as T;
}

export function deepCopy<T>(source: T): T {
    return JSON.parse(JSON.stringify(source));
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T {
    if ((Object.values(enm) as unknown as string[]).includes(value)) {
        return value as unknown as T;
    }
    throw new Error(`Cannot map ${value} to enum of type ${typeof enm}`);
}

export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function toggleInSet(set: Set<string>, key: string): Set<string> {
    let newValues = Array.from(set);
    if (set.has(key)) {
        newValues = newValues.filter((el) => el !== key);
    } else {
        newValues = [...newValues, key];
    }

    return new Set(newValues);
}
