import React from 'react';

import assetsResolver from '@/c/assetsResolver';

interface Props {
    className?: string;
}
const LogoImage: React.FC<Props> = (p) => {
    return (
        <img
            src={assetsResolver.image('core/logo.svg')}
            alt={'Deuslibra logo'}
            width={'auto'}
            height={'35px'}
            className={p.className}
        />
    );
};
export default LogoImage;
