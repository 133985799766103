import React, { memo, useContext } from 'react';

import { hasPermissions } from '@/c/auth/utils';
import { UserContext } from '@/c/contexts/UserContext';

export interface PermissionsGateProps {
    requiredPermissions: string[];
    children: React.ReactNode;
}

const PermissionsGate: React.FC<PermissionsGateProps> = (p) => {
    const { data } = useContext(UserContext);

    if (data.loading || data.error) {
        return <div>401-guest-not-allowed</div>;
    }

    const permissions = data.value!.config.permissions;

    if (hasPermissions(permissions, p.requiredPermissions)) {
        return <>{p.children}</>;
    }

    return <div>401</div>;
};
export default memo(PermissionsGate);
