import { STORAGE_KEYS } from '@/c/services/storage';

import { ApiLoginResponse } from '../../pages/api/login';
import storage from '../storage';

type Session = ApiLoginResponse;

export function setSession(data: Session): boolean {
    storage.setItem(STORAGE_KEYS.SESSION, data);
    return true;
}

export function setSessionAccessToken(at: { accessToken: string; accessTokenExpiresAt: string }): void {
    storage.setItem(STORAGE_KEYS.SESSION, {
        ...JSON.parse(storage.getItem(STORAGE_KEYS.SESSION) ?? '{}'),
        accessToken: at.accessToken,
        accessTokenExpiresAt: at.accessTokenExpiresAt,
    } as Session);
}

export function getSession(): Session | null {
    if (!(typeof window !== 'undefined' && window && window.localStorage)) {
        return null;
    }

    const session = storage.getItem(STORAGE_KEYS.SESSION);
    if (session === null) {
        return null;
    }

    return JSON.parse(session) as Session;
}

export function getToken(): string | null {
    return getSession()?.accessToken ?? null;
}

export function getRefreshToken(): string | null {
    return getSession()?.refreshToken ?? null;
}

export function isApiLoggedIn(): boolean {
    return typeof window !== 'undefined' && window && window.localStorage.getItem(STORAGE_KEYS.SESSION) !== null;
}
