/// <reference types="@welldone-software/why-did-you-render" />
//https://github.com/vercel/next.js/tree/main/examples/with-why-did-you-render
import React from 'react';

if (process.env.WDYR !== 'false' && process.env.NODE_ENV === 'development') {
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React, {
            trackAllPureComponents: true,
        });
    }
}
