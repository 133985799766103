import React from 'react';

import { createPortal } from 'react-dom';

import { useStateCallback } from '../../hooks/useStateCallback';
import { numericHash } from '../../utils/datas';
import { ModalContext } from './ModalContext';

type State = Record<
    string,
    {
        component: React.FC<any>;
        props: any;
        domEl: HTMLElement;
    }
>;

const ModalProvider: React.FC = (p) => {
    const [modalsState, setModalsState] = useStateCallback<State>({});

    return (
        <ModalContext.Provider
            value={{
                showModal: (modal, props) => {
                    const id = formModalId(modal);
                    const newDiv = document.createElement('div');
                    newDiv.setAttribute('id', id);
                    document.body.appendChild(newDiv);

                    setModalsState((prevState) => ({
                        ...prevState,
                        [id]: {
                            component: modal,
                            props: props,
                            domEl: newDiv,
                        },
                    }));
                },
                destroyModal: (modalId) => {
                    if (!Object.prototype.hasOwnProperty.call(modalsState, modalId)) {
                        return;
                    }

                    const mState = modalsState[modalId];
                    setModalsState(
                        Object.keys(modalsState).reduce<State>((p, key) => {
                            if (key !== modalId) {
                                p[key] = modalsState[key];
                            }
                            return p;
                        }, {}),
                        () => {
                            const domEl = mState.domEl;
                            document.body.removeChild(domEl);
                        }
                    );
                },
                isCurrent: (modalId) => {
                    return Array.from(Object.keys(modalsState)).pop() === modalId;
                },
            }}
        >
            {Object.keys(modalsState).map((k, i) => {
                // @ts-ignore
                const Component = modalsState[k].component;
                // @ts-ignore
                const props = modalsState[k].props;

                return createPortal(<Component key={i} {...props} modalId={k} />, modalsState[k].domEl);
            })}
            {p.children}
        </ModalContext.Provider>
    );
};
export default ModalProvider;

function formModalId(modal: React.FC<any>): string {
    // @ts-ignore
    const name = modal.displayName as string | undefined;
    return `m-${name ? `${numericHash(name)}-` : ''}${Date.now()}`;
}
