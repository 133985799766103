import Env from '@/c/Env';
import cheaders from '@/c/http/cheaders';
import { ensureIsClientSide } from '@/c/http/middlewares/helpers';
import { generateRequestId } from '@/c/utils/datas';

export default function beforeSendAddTracking(ri: RequestInit): RequestInit {
    ensureIsClientSide();
    //const userId = store.getState()?.user?.id || 'null';
    const requestId = `${generateRequestId()}`;

    return {
        ...ri,
        headers: {
            ...ri.headers,
            ...{
                [cheaders.X_CLIENT_VERSION]: Env.version,
                [cheaders.X_REQUEST_ID]: requestId,
            },
        },
    };
}
