import React from 'react';

import { Alert } from '@mui/lab';
import { Button, LinearProgress } from '@mui/material';
import { QueryStatus } from 'react-query';

interface Props {
    i18n: {
        retryBtn: string;
    };
    text?: string;
    status: QueryStatus;
    error: Error | null;
    retryFn?: VoidFunction;
}

const NetworkStatus: React.FC<Props> = ({ i18n, status, error, text, retryFn }) => {
    switch (status) {
        case QueryStatus.Success:
        case QueryStatus.Idle:
            return null;
        case QueryStatus.Error:
            return (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Alert severity="error">{text ?? `Fetch data failed ${(error as Error).message}`}</Alert>
                    {retryFn && <Button onClick={retryFn}>{i18n.retryBtn}</Button>}
                </div>
            );
        case QueryStatus.Loading:
            return <LinearProgress color="primary" />;
    }
};
export default NetworkStatus;
