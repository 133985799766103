export default class Storage {
    private isLocalStorageAvailable: boolean;
    private map: Record<string, any>;

    constructor() {
        this.isLocalStorageAvailable = isLocalStorageAvailable();
        this.map = {};
    }

    setItem(key: string, value: any) {
        let parsedValue = value;
        if (Array.isArray(value) || (value instanceof Object && value.constructor === Object)) {
            parsedValue = JSON.stringify(value);
        } else {
            parsedValue.toString();
        }

        if (this.isLocalStorageAvailable) {
            window.localStorage.setItem(key, parsedValue);
        } else {
            this.map[key] = parsedValue;
        }
    }

    removeItem(key: string) {
        if (this.isLocalStorageAvailable) {
            window.localStorage.removeItem(key);
        } else {
            delete this.map[key];
        }
    }

    getItem(key: string) {
        if (this.isLocalStorageAvailable) {
            return window.localStorage.getItem(key);
        } else {
            return this.map[key] || null;
        }
    }
}

function isLocalStorageAvailable() {
    const test = 'test';
    try {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}
