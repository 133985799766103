import { UrlObject } from 'url';

import React from 'react';

import styled from 'styled-components';

import Link from './Link';

export const SimpleLink = styled.a`
    &:focus,
    &:hover {
        text-decoration: none;
    }
`;

const ForwardedLink: React.FC<{
    name: string | UrlObject;
    href?: string;
    prefetch?: boolean;
    className?: string;
    children: React.ReactNode;
    rel?: string;
    title?: string;
}> = ({ children, ...props }) => {
    return (
        <Link {...props} passHref={true}>
            <SimpleLink rel={props.rel} title={props.title} className={props.className}>
                {children}
            </SimpleLink>
        </Link>
    );
};

export default ForwardedLink;
