import { RequiredFieldFormI18n, TFunction } from '@/c/translations/types';

export function scopedT(t: TFunction, scopeNs: string): TFunction {
    return (...args) =>
        // @ts-ignore
        t(`${scopeNs}:${args[0]}`, ...args.slice(1));
}

export const tFieldLabel = (t: TFunction, name: string): string => {
    return t(`form.fields.${name}.label`);
};

export const tFieldPlaceholder = (t: TFunction, name: string): string => {
    return t(`form.fields.${name}.placeholder`);
};

export const tField = (t: TFunction, name: string): RequiredFieldFormI18n => {
    return {
        label: t(`${name}.label`),
        validation: t(`${name}.validation`, {
            returnObjects: true,
        }),
    };
};
