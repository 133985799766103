import React, { useEffect, useState } from 'react';

import Drawer from '@mui/material/Drawer';

interface Props {
    isMenuOpen: boolean;
    items: React.ReactNode;
    onOpen: VoidFunction;
    onClose: VoidFunction;
}

const drawerWidth = 200;

export default function MenuDrawer(p: Props): React.ReactElement {
    const [isMenuOpen, setIsMenuOpen] = useState(p.isMenuOpen);

    useEffect(() => {
        setIsMenuOpen(p.isMenuOpen);
    }, [p.isMenuOpen]);

    return (
        <Drawer
            anchor={'right'}
            open={isMenuOpen}
            variant="temporary"
            onClose={p.onClose}
            sx={{
                flexShrink: 0,
                whiteSpace: 'nowrap',
            }}
            PaperProps={{
                sx: {
                    width: drawerWidth,
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
        >
            {p.items}
        </Drawer>
    );
}
