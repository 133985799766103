import { styled as matStyled } from '@mui/material';

const drawerWidth = 240;

export const classes = {
    hide: 'hide',
    appBar: 'appBar',
    appBarShift: 'appBarShift',
    menuButton: 'menuButton',
};

export const HeaderWrapper = matStyled('div')(({ theme }) => ({
    [`.${classes.hide}`]: {
        display: 'none',
    },
    [`.${classes.appBar}`]: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    [`.${classes.appBarShift}`]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    [`.${classes.menuButton}`]: {
        marginLeft: 36,
    },
}));
