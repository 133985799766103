import React from 'react';

import { GetServerSideProps } from 'next';
import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { Action, Subject } from '@/c/auth/permissions';
import { formPermission } from '@/c/auth/utils';
import withPermissionsGate from '@/c/auth/withPermissionsGate';
import NetworkStatus from '@/c/components/molecules/NetworkStatus';
import ExercisesPage from '@/c/components/pages/ExercisesPage/ExercisesPage';
import { ExerciseModel } from '@/c/components/types';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { useDeleteExerciseMutation, useExercises } from '@/c/data/exercises';
import { croutes } from '@/c/routes';
import SsApiHttp from '@/c/SsApiHttp';
import { scopedT } from '@/c/translations/utils';
import { linkRendered } from '@/core/linkRenderer';
import { useRouter } from '@/core/router';

import { getRouteSource } from '../../getRouteSource';
import { NextPageProps } from '../../types/NextPageProps';
import { withSessionSsr } from '../../utils/withSession';

type ExtraProps = {
    exercises?: ExerciseModel[];
};

const T_NAMESPACE = 'exercises-page';

const Page = ({ t, ...p }: NextPageProps & ExtraProps) => {
    const nt = scopedT(t, T_NAMESPACE);
    const router = useRouter();

    const { data, status, error, refetch } = useExercises({
        enabled: p.exercises === undefined,
    });
    const exercises = p.exercises || data;
    const [deleteExerciseMutation] = useDeleteExerciseMutation();

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
            </Head>
            <main>
                <NetworkStatus
                    i18n={{ retryBtn: t('retryFetchBtn') }}
                    status={status}
                    error={error}
                    retryFn={refetch}
                />
                {exercises && (
                    <ExercisesPage
                        exercises={exercises}
                        onCreateClick={() => {
                            router.push(getRouteSource(croutes.EXERCISE_CREATE));
                        }}
                        renderLink={linkRendered}
                        onDeleteRequested={async (id) => {
                            const response = (await deleteExerciseMutation(id)) as Response;
                            if (response.status !== 200) {
                                alert(await response.statusText);
                            }
                        }}
                    />
                )}
            </main>
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];

export const getServerSideProps: GetServerSideProps<ExtraProps> = withSessionSsr(async ({ locale, req }) => {
    const user = req.session.user;
    const isLoggedIn = user !== undefined;

    const p: ExtraProps = {};
    if (!isLoggedIn) {
        const ssapi = SsApiHttp(locale);
        const res = await ssapi.get('/exercises');
        p.exercises = await res.json();
    }

    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
            ...p,
        },
    };
});

export const exercisesRequiredPermissions = [
    formPermission(Subject.EXERCISE, Action.LIST),
    formPermission(Subject.EXERCISE, Action.VIEW),
];

export default withPermissionsGate(withTranslation(tNamespaces)(Page), exercisesRequiredPermissions);
