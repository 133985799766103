import { UrlObject } from 'url';

import React from 'react';

import { default as NextLink } from 'next/link';

import { getRouteSource } from '../../getRouteSource';

const Link: React.FC<{
    name: string | UrlObject;
    href?: string;
    passHref?: boolean;
    prefetch?: boolean;
    className?: string;
}> = ({ name, href, children, ...props }) => {
    return (
        <NextLink {...props} href={href || getRouteSource(name)}>
            {children}
        </NextLink>
    );
};

export default Link;
