import cheaders from '@/c/http/cheaders';
import { ensureIsClientSide } from '@/c/http/middlewares/helpers';
import { getCurrentLocale } from '@/c/locale';

export default function beforeSendAddLocale(ri: RequestInit): RequestInit {
    ensureIsClientSide();
    return {
        ...ri,
        headers: {
            ...ri.headers,
            ...{
                [cheaders.ACCEPT_LANGUAGE]: getCurrentLocale(),
            },
        },
    };
}
