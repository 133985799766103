import { getMediaQuery, getRem } from '../utils/utils';
import breakpoints from './breakpoints';
import space from './space';

const rem = getRem();
const spacing = (val: keyof typeof space): string => rem(space[val]);
const mediaQuery = getMediaQuery(breakpoints);

export default {
    utils: { rem, spacing },
    breakpoints,
    space,
    mediaQuery,
};
