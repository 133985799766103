import React from 'react';

interface Props {
    error: Error | Response;
}

const ErrorView: React.FC<Props> = ({ error }) => {
    return (
        <div>
            <p>
                {' '}
                Error fetching data:{' '}
                {(error as Response).status !== undefined ? (error as Response).status : (error as Error).message}
            </p>
            <p>Please try again later by refreshing the page, or contact support</p>
        </div>
    );
};
export default ErrorView;
