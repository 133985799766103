import React from 'react';

import styled, { css } from 'styled-components';

export const fsStyle = css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 999;
    width: 100%;
`;

export const FullScreen = styled.div`
    ${fsStyle}
`;

const Wrapper = styled.div`
    ${(props) => props.theme.mediaQuery.max.md`
        ${fsStyle}
    `};
`;

interface Props {
    topInPx?: number;
    subtractHeightInPx?: number;
    paddingBottomInPx?: number;
}

const FullScreenMobileOnly: React.FC<Props> = (p) => {
    return (
        <Wrapper
            style={{
                top: `${p.topInPx ?? 0}`,
                maxHeight: `calc(100% - ${p.subtractHeightInPx ?? 0}px)`,
                paddingBottom: `${p.paddingBottomInPx ?? 0}px`,
            }}
        >
            {p.children}
        </Wrapper>
    );
};
export default FullScreenMobileOnly;
