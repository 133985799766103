import { useMutation, useQuery } from 'react-query';
import { MutationResultPair, QueryConfig, QueryResult } from 'react-query/types/core/types';

import api from '@/c/api';
import { createModule } from '@/c/data/common';
import {
    CreateCategoryRequest,
    UpdateCategoryRequest,
    createCategory,
    deleteCategory,
    updateCategory,
} from '@/c/services/mutations';
import { getCategories } from '@/c/services/queries';
import { CategoryDto } from '@/c/types/api';

type T = CategoryDto;
const ITEM_KEY = 'category';
const COLLECTION_KEY = 'categories';

const { addItem, deleteItem, updateItem } = createModule<T>({
    itemKey: ITEM_KEY,
    collectionKey: COLLECTION_KEY,
});

export const useCategory = (id: string, queryConfig?: QueryConfig<T, Error>): QueryResult<T, Error> =>
    useQuery<T, Error>([ITEM_KEY, id], () => api.get(`/categories/${id}`).then((res) => res.json()), queryConfig);

export const useCategories = (): QueryResult<T[], Error> => useQuery<T[], Error>(COLLECTION_KEY, () => getCategories());

export const useCreateCategoryMutation = (): MutationResultPair<T, Error, CreateCategoryRequest, unknown> =>
    useMutation(createCategory, {
        onSuccess: addItem,
    });

export const useUpdateCategoryMutation = (): MutationResultPair<T, Error, UpdateCategoryRequest, unknown> =>
    useMutation(updateCategory, {
        onSuccess: updateItem,
    });

export const useDeleteCategoryMutation = (): MutationResultPair<void, Error, string, unknown> =>
    useMutation(deleteCategory, {
        onSuccess: (_, id) => {
            deleteItem(id);
        },
    });
