import afterCheckIfMaintenance from '@/c/http/middlewares/afterCheckIfMaintenance';
import beforeSendAddTracking from '@/c/http/middlewares/beforeSendAddTracking';

import { fetchFn } from './http';
import HttpClient from './HttpClient';
import beforeSendAddLocale from './middlewares/beforeSendAddLocale';
import beforeSendContentJson from './middlewares/beforeSendContentJson';

export default class SLocalizedHttpClient extends HttpClient {
    constructor(basePath = '') {
        super(
            fetchFn(fetch, basePath, {
                before: [beforeSendContentJson, beforeSendAddTracking, beforeSendAddLocale],
                after: [afterCheckIfMaintenance],
            })
        );
    }
}
