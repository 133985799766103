import Env from '@/c/Env';
import { getSession, setSessionAccessToken } from '@/c/http/api';
import { fetchFn } from '@/c/http/http';
import HttpClient from '@/c/http/HttpClient';
import afterCheckIfMaintenance from '@/c/http/middlewares/afterCheckIfMaintenance';
import beforeSendAddLocale from '@/c/http/middlewares/beforeSendAddLocale';
import beforeSendAddTracking from '@/c/http/middlewares/beforeSendAddTracking';
import beforeSendContentJson from '@/c/http/middlewares/beforeSendContentJson';
import logout from '@/c/logout';
import { croutes, resolveRoute } from '@/c/routes';
import UsersAuthService from '@/c/services/UsersAuthService';
import { cloneResponse } from '@/c/utils/http';

import beforeSendAddAuth from './http/middlewares/beforeSendAddAuth';

const requestWithAccessTokenRenewalIfExpired = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const res = await request(input, init);
    const clonedResponse = await cloneResponse(res);

    if (res.status === 401) {
        if (res.body !== null) {
            let jbody = null;

            try {
                jbody = JSON.parse(await res.text());
            } catch (err) {}

            if (jbody['code'] === 'token_expired') {
                try {
                    const data = await UsersAuthService.getInstance().renewAccessToken(getSession()!.refreshToken);
                    setSessionAccessToken(data);

                    return request(input, init);
                } catch (_) {}
            }
        }

        await logout(
            resolveRoute({
                pathname: croutes.LOGIN,
                query: {
                    ref: 'invretok',
                },
            })
        );

        // We should return a fresh stream after we read the body above to avoid the error "Bad state: Stream has already been listened to" and let other consumers read the body again
        return clonedResponse;
    }

    return res;
};

const request = fetchFn(fetch, Env.api.endpoint, {
    before: [beforeSendContentJson, beforeSendAddTracking, beforeSendAddLocale, beforeSendAddAuth],
    after: [afterCheckIfMaintenance],
});

/**
 * api that requires authorization and logs out automatically if token is invalid
 */
export default new HttpClient(requestWithAccessTokenRenewalIfExpired);
