import prem from 'polished/lib/helpers/rem';
import { CSSObject, Interpolation, InterpolationFunction, ThemedStyledProps, css } from 'styled-components';

import { BreakpointName, Breakpoints, MediaQueries } from '../ThemeInterface';

export const getRem: () => (val: number) => string = () => (val: number) => prem(val);

type CssArgs = [
    // eslint-disable-next-line @typescript-eslint/ban-types
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<{}, any>>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...interpolations: Array<Interpolation<ThemedStyledProps<{}, any>>>
];

/**
 * Take breakpoints config and return media query
 * functions ready to use in styles
 */
export const getMediaQuery = (b: Breakpoints): MediaQueries =>
    Object.keys(b).reduce(
        (finalMedia: MediaQueries, breakpoint: string) => ({
            ...finalMedia,
            min: {
                ...finalMedia.min,
                [breakpoint]: (...args: CssArgs) =>
                    css`
                        @media (min-width: ${b[breakpoint as BreakpointName].min}px) {
                            ${css(...args)};
                        }
                    `,
            },
            /**
             * @deprecated - prefer to use min when possible for mobile-first approach
             */
            max: {
                ...finalMedia.max,
                [breakpoint]: (...args: CssArgs) =>
                    css`
                        @media (max-width: ${b[breakpoint as BreakpointName].max}px) {
                            ${css(...args)};
                        }
                    `,
            },
            /**
             * @deprecated - prefer to use min when possible for mobile-first approach
             */
            range: {
                ...finalMedia.range,
                [breakpoint]: (...args: CssArgs) =>
                    css`
                        @media (min-width: ${b[breakpoint as BreakpointName].min}px) and (max-width: ${b[
                                breakpoint as BreakpointName
                            ].max}px) {
                            ${css(...args)};
                        }
                    `,
            },
        }),
        {} as MediaQueries
    ) as MediaQueries;
