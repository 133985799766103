import { useMutation, useQuery } from 'react-query';
import { MutationResultPair, QueryConfig, QueryResult } from 'react-query/types/core/types';

import api from '@/c/api';
import { createModule } from '@/c/data/common';
import {
    CreateBodyPartRequest,
    UpdateBodyPartRequest,
    createBodyPart,
    deleteBodyPart,
    updateBodyPart,
} from '@/c/services/mutations';
import { getBodyParts } from '@/c/services/queries';
import { BodyPartDto } from '@/c/types/api';

type T = BodyPartDto;
const ITEM_KEY = 'bodypart';
const COLLECTION_KEY = 'bodyparts';

const { addItem, deleteItem, updateItem } = createModule<T>({
    itemKey: ITEM_KEY,
    collectionKey: COLLECTION_KEY,
});

export const useBodypart = (id: string, queryConfig?: QueryConfig<T, Error>): QueryResult<T, Error> =>
    useQuery<T, Error>([ITEM_KEY, id], () => api.get(`/bodyparts/${id}`).then((res) => res.json()), queryConfig);

export const useBodyparts = (): QueryResult<T[], Error> => useQuery<T[], Error>(COLLECTION_KEY, () => getBodyParts());

export const useCreateBodyPartMutation = (): MutationResultPair<T, Error, CreateBodyPartRequest, unknown> =>
    useMutation(createBodyPart, {
        onSuccess: addItem,
    });

export const useUpdateBodyPartMutation = (): MutationResultPair<T, Error, UpdateBodyPartRequest, unknown> =>
    useMutation(updateBodyPart, {
        onSuccess: updateItem,
    });

export const useDeleteBodyPartMutation = (): MutationResultPair<void, Error, string, unknown> =>
    useMutation(deleteBodyPart, {
        onSuccess: (_, id) => {
            deleteItem(id);
        },
    });
