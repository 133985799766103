import { STORAGE_KEYS } from '@/c/services/storage';

import { getRefreshToken } from './http/api';
import { croutes, resolveRoute } from './routes';

export default function logout(redirectTo?: string): Promise<void> {
    return new Promise((resolve) => {
        fetch('/wapi/v1/logout', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getRefreshToken()}`,
            },
        }).finally(() => {
            if (typeof window !== 'undefined') {
                window.localStorage.removeItem(STORAGE_KEYS.SESSION);
                window.location.href = redirectTo || resolveRoute(croutes.LOGIN);
            }
            resolve();
        });
    });
}
