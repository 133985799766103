import { UrlObject } from 'url';

import { MenuItem, NamedLinkMenuItem } from '@/c/components/molecules/Menu/MenuListItem';
import Tracking from '@/c/constants/Tracking';
import { isObject } from '@/c/utils/equals';
import { NextRouter } from '@/core/router';

import { transformParametrizedPath } from '../../../getRouteSource';

export function isCurrentPage(current: UrlObject | string, router: NextRouter): boolean {
    const tp = transformParametrizedPath(router.pathname);
    let isActive = (typeof current === 'string' && tp === current) || tp === (current as UrlObject).pathname;

    if ((current as UrlObject).pathname && isObject((current as UrlObject).query)) {
        const query = (current as UrlObject).query as Record<string, unknown>;
        Object.entries(query).map((value) => {
            const key = value[0];
            if (key === Tracking.REF_QUERY_PARAM_KEY) {
                return;
            }
            isActive = isActive && value[1] === router.query[key];
        });
    }

    return isActive;
}

export function menuItemActiveClassname(mi: MenuItem, router: NextRouter): string | undefined {
    return (mi as NamedLinkMenuItem).name
        ? isCurrentPage((mi as NamedLinkMenuItem).name, router)
            ? 'active'
            : undefined
        : undefined;
}
