import React, { createContext } from 'react';

export type ModalContextValue = {
    showModal: <T>(modal: React.FC<T>, props: T) => void;
    destroyModal: (modalId: string) => void;
    isCurrent: (modalId: string) => boolean;
};

export const ModalContext = createContext<ModalContextValue | undefined>(undefined);
ModalContext.displayName = 'ModalContext';
