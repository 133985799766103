import React from 'react';

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { Action, Subject } from '@/c/auth/permissions';
import { formPermission } from '@/c/auth/utils';
import withPermissionsGate from '@/c/auth/withPermissionsGate';
import NetworkStatus from '@/c/components/molecules/NetworkStatus';
import { default as CategoriesPageComp } from '@/c/components/pages/CategoriesPage/CategoriesPage';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { useCategories } from '@/c/data/categories';
import { croutes } from '@/c/routes';
import { scopedT } from '@/c/translations/utils';
import { chooseInRightLocaleArray } from '@/c/utils/localeMapper';
import { linkRendered } from '@/core/linkRenderer';
import { useRouter } from '@/core/router';

import { getRouteSource } from '../../getRouteSource';
import { NextPageProps } from '../../types/NextPageProps';

const T_NAMESPACE = 'categories-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);
    const router = useRouter();
    const { data, status, error, refetch } = useCategories();

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
            </Head>
            <main>
                <NetworkStatus
                    i18n={{ retryBtn: t('retryFetchBtn') }}
                    status={status}
                    error={error}
                    retryFn={refetch}
                />
                {data && (
                    <CategoriesPageComp
                        categories={chooseInRightLocaleArray(data, 'name')}
                        onCreateClick={() => {
                            router.push(getRouteSource(croutes.CATEGORY_CREATE));
                        }}
                        renderLink={linkRendered}
                    />
                )}
            </main>
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];

export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export const categoriesRequiredPermissions = [
    formPermission(Subject.CATEGORY, Action.LIST),
    formPermission(Subject.CATEGORY, Action.VIEW),
];

export default withPermissionsGate(withTranslation(tNamespaces)(Page), categoriesRequiredPermissions);
