import { UrlObject } from 'url';

import React from 'react';

import styled from 'styled-components';

import Link from './Link/Link';

export type LinkRenderer = (
    url: string | UrlObject,
    view: React.ReactNode,
    options?: { wrappedInLink: boolean }
) => React.ReactElement;

const StyledLink = styled.a`
    text-decoration: none;
`;

const linkRendered: LinkRenderer = (name: string | UrlObject, view, options = { wrappedInLink: true }) => (
    <Link name={name} passHref={options.wrappedInLink}>
        {options.wrappedInLink ? <StyledLink>{view}</StyledLink> : { view }}
    </Link>
);

export { linkRendered };
