import api from '@/c/api';
import {
    CombinedExerciseEntry,
    Description,
    ExerciseModel,
    Image,
    RoutineEntryType,
    RoutineUnitsConfig,
    SetCompletionInfo,
    SingleExerciseEntry,
} from '@/c/components/types';
import paths from '@/c/http/paths';

import { BodyPartDto, CategoryDto, Routine, UserConfig, Workout } from '../types/api';
import { Action, LocalisedEntry, LocalisedString } from '../types/common';

export type CreateBodyPartRequest = { name: LocalisedString };
export function createBodyPart(e: CreateBodyPartRequest): Promise<BodyPartDto> {
    return api.post(`/bodyparts`, e).then(async (res) => {
        if (res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export type UpdateBodyPartRequest = { id: string; name: LocalisedString };
export function updateBodyPart(e: UpdateBodyPartRequest): Promise<BodyPartDto> {
    return api.put(`/bodyparts/${e.id}`, e).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function deleteBodyPart(id: string): Promise<void> {
    return api.delete(`/bodyparts/${id}`).then(async (res) => {
        if (res.status === 200) {
            return;
        }
        throw new Error(await res.text());
    });
}

export type CreateCategoryRequest = { name: LocalisedString };
export function createCategory(e: CreateCategoryRequest): Promise<CategoryDto> {
    return api.post(`/categories`, e).then(async (res) => {
        if (res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export type UpdateCategoryRequest = { id: string; name: LocalisedString };
export function updateCategory(e: UpdateCategoryRequest): Promise<CategoryDto> {
    return api.put(`/categories/${e.id}`, e).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function deleteCategory(id: string): Promise<void> {
    return api.delete(`/categories/${id}`).then(async (res) => {
        if (res.status === 200) {
            return;
        }
        throw new Error(await res.text());
    });
}

export type CreateExerciseRequest = {
    name: LocalisedString;
    description: LocalisedEntry<Description>;
    bodyParts: string[];
    categories: string[];
    metrics: string[];
};
export function createExercise(e: CreateExerciseRequest): Promise<ExerciseModel> {
    return api.post(`/exercises`, e).then(async (res) => {
        if (res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export type UpdateExerciseRequest = {
    id: string;
    name: LocalisedString;
    description: LocalisedEntry<Description>;
    actions: Action[];
};
export function updateExercise(e: UpdateExerciseRequest): Promise<ExerciseModel> {
    return api.put(`/exercises/${e.id}`, e).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function updateExerciseImage(exerciseId: string, file: File): Promise<Image> {
    const formData = new FormData();
    formData.append('image', file);

    return api.patch(api.url(paths.exercise, [exerciseId]), formData).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    });
}

export function deleteExercise(id: string): Promise<Response> {
    return api.delete(`/exercises/${id}`);
}

interface ExerciseSetData {
    id: string;
    rest: number;
    metrics: {
        id: string;
        value: string | number;
    }[];
    completionInfo?: SetCompletionInfo;
}

export type ExerciseEntryData = SingleExerciseEntryData | CombinedExerciseEntryData;

export interface SingleExerciseEntryData {
    id: string;
    exerciseId: string;
    sets: ExerciseSetData[];
}

export interface CombinedExerciseEntryData {
    id: string;
    exercises: SingleExerciseEntryData[];
    repetitions: number;
    rest: number;
    circuit: boolean;
}

export interface CreateUpdateRoutineRequest {
    id: string;
    name: string;
    entries: {
        id: string;
        type: RoutineEntryType;
        exerciseEntries: ExerciseEntryData[];
    }[];
    unitsConfig: RoutineUnitsConfig;
}

export function createRoutine(e: CreateUpdateRoutineRequest): Promise<Routine> {
    return api.post(`/routines`, e).then(async (res) => {
        if (res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function updateRoutine(r: CreateUpdateRoutineRequest): Promise<Routine> {
    return api.put(`/routines/${r.id}`, r).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function deleteRoutine(id: string): Promise<Response> {
    return api.delete(`/routines/${id}`);
}

export interface CreateUpdateWorkoutRequest {
    id: string;
    name: string;
    routineId?: string;
    entries: {
        id: string;
        type: RoutineEntryType;
        exerciseEntries: ExerciseEntryData[];
    }[];
    createdAt?: string;
    totalDurationInSeconds: number;
    unitsConfig: RoutineUnitsConfig;
}

export function createWorkout(e: CreateUpdateWorkoutRequest): Promise<Workout> {
    return api.post(`/workouts`, e).then(async (res) => {
        if (res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function updateWorkout(r: CreateUpdateWorkoutRequest): Promise<Workout> {
    return api.put(`/workouts/${r.id}`, r).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });
}

export function deleteWorkout(id: string): Promise<Response> {
    return api.delete(`/workouts/${id}`);
}

function singleExerciseEntryToData(e: SingleExerciseEntry): SingleExerciseEntryData {
    return {
        id: e.id,
        exerciseId: e.exercise.id,
        sets: e.sets.map((s) => ({
            id: s.id,
            rest: s.rest,
            metrics: s.metrics.map((m) => ({
                id: m.metric.id,
                value: m.value,
            })),
            completionInfo: s.completionInfo,
        })),
    };
}

function combinedExerciseEntryToData(e: CombinedExerciseEntry): CombinedExerciseEntryData {
    return {
        id: e.id,
        repetitions: e.repetitions,
        rest: e.rest,
        circuit: e.circuit || false,
        exercises: e.exercises.map((see) => singleExerciseEntryToData(see)),
    };
}

export function routineToCreateUpdateRequest(m: Routine): CreateUpdateRoutineRequest {
    return {
        id: m.id,
        name: m.name,
        entries: m.entries.map((e) => ({
            id: e.id,
            type: e.type,
            exerciseEntries: e.exerciseEntries.map((ee) => {
                if ((ee as CombinedExerciseEntry).exercises) {
                    return combinedExerciseEntryToData(ee as CombinedExerciseEntry);
                } else {
                    return singleExerciseEntryToData(ee as SingleExerciseEntry);
                }
            }),
        })),
        unitsConfig: m.unitsConfig,
    };
}

export function workoutToCreateUpdateRequest(m: Workout): CreateUpdateWorkoutRequest {
    return {
        id: m.id,
        name: m.name,
        routineId: m.routineId,
        entries: m.entries.map((e) => ({
            id: e.id,
            type: e.type,
            exerciseEntries: e.exerciseEntries.map((ee) => {
                if ((ee as CombinedExerciseEntry).exercises) {
                    return combinedExerciseEntryToData(ee as CombinedExerciseEntry);
                } else {
                    return singleExerciseEntryToData(ee as SingleExerciseEntry);
                }
            }),
        })),
        createdAt: m.created_at,
        totalDurationInSeconds: m.totalDurationInSeconds,
        unitsConfig: m.unitsConfig,
    };
}

export const putUserConfig = (config: UserConfig): Promise<UserConfig> =>
    api.put(`/user/config`, config).then(async (res) => {
        if (res.status === 200 || res.status === 201) {
            return res.json();
        }
        throw new Error(await res.text());
    });
