import React from 'react';

import styled from 'styled-components';

const WrappedContainer = styled.div`
    padding: 75px 10px 50px;
`;

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function PageContents({ children, ...p }: Props): React.ReactElement {
    return (
        <WrappedContainer id={'page-contents'} className={p.className}>
            {children}
        </WrappedContainer>
    );
}
