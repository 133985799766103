import React, { useCallback, useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { TFunction, withTranslation } from 'next-i18next';

import { hasPermissions } from '@/c/auth/utils';
import LogoImage from '@/c/components/molecules/LogoImage';
import MenuListItem, { NamedLinkMenuItem } from '@/c/components/molecules/Menu/MenuListItem';
import ErrorView from '@/c/components/templates/LoggedLayout/ErrorView';
import MenuDrawer from '@/c/components/templates/MenuDrawer/MenuDrawer';
import PageContents from '@/c/components/templates/PageContents/PageContents';
import { LayoutProps } from '@/c/components/templates/types';
import useViewManager from '@/c/components/templates/ViewManager/useViewManager';
import { UserContext } from '@/c/contexts/UserContext';
import { LoadableData } from '@/c/data/types';
import useDidMountEffect from '@/c/hooks/useDidMountEffect';
import logout from '@/c/logout';
import { croutes } from '@/c/routes';
import swebhttp from '@/c/swebhttp';
import { scopedT } from '@/c/translations/utils';
import { MeUser } from '@/c/types/api';
import { LProps } from '@/c/types/LProps';
import { getJson } from '@/c/utils/http';

import { bodyPartsRequiredPermissions } from '../../../../pages/bodyparts';
import { categoriesRequiredPermissions } from '../../../../pages/categories';
import { exercisesRequiredPermissions } from '../../../../pages/exercises';
import { routinesRequiredPermissions } from '../../../../pages/routines';
import BigLoader from '../../molecules/BigLoader/BigLoader';
import Header from '../../organisms/Header/Header';

const LoggedLayout: React.FC<LayoutProps & LProps> = ({ children, t }) => {
    const headerT = scopedT(t, 'header');

    const { viewManager } = useViewManager();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => setIsDrawerOpen(false);

    const { data, setData } = useContext(UserContext);

    useDidMountEffect(() => {
        swebhttp
            .get('/wapi/v1/user')
            .then(async (r) => {
                if (r.status === 200) {
                    setData(LoadableData.value((await getJson<MeUser>(r))!));
                } else {
                    throw r;
                }
            })
            .catch((reason) => setData(LoadableData.error(reason)));
    });

    const permissions = data?.value?.config?.permissions || [];

    return (
        <>
            {viewManager.header && (
                <Header fixedMenuItems={getMenuItems(headerT, permissions)} handleToggleDrawer={toggleDrawer} />
            )}
            <MenuDrawer
                isMenuOpen={isDrawerOpen}
                onOpen={() => setIsDrawerOpen(true)}
                onClose={closeDrawer}
                items={
                    <Box onClick={closeDrawer}>
                        <ListItem style={{ cursor: 'pointer' }} onClick={closeDrawer}>
                            <LogoImage />
                        </ListItem>
                        <Divider style={{ margin: '0.2rem 0', background: 'white' }} />
                        {getSettingsMenuItems(headerT, permissions)}
                        {getUserProfileMenuItems(headerT)}
                    </Box>
                }
            />
            {data.loading && (
                <PageContents>
                    <BigLoader />
                </PageContents>
            )}
            {data.error && (
                <PageContents>
                    <ErrorView error={data.error} />
                </PageContents>
            )}
            {!data.error && !data.loading && data && (
                <Grid>
                    <PageContents>{children}</PageContents>
                </Grid>
            )}
        </>
    );
};

const tNamespaces = ['common', 'header'];
export default withTranslation(tNamespaces)(LoggedLayout);

function getMenuItems(t: TFunction, permissions: string[]): React.ReactNode {
    return (
        <>
            {getCommonTextMenuItems(t, permissions).map((item, i) => (
                <MenuListItem key={i} mi={item} />
            ))}
        </>
    );
}

function getCommonTextMenuItems(t: TFunction, permissions: string[]): NamedLinkMenuItem[] {
    const menuItems: NamedLinkMenuItem[] = [
        {
            name: croutes.PROGRESS,
            text: t('progress'),
        },
    ];

    if (hasPermissions(permissions, routinesRequiredPermissions)) {
        menuItems.push({
            name: croutes.ROUTINES,
            text: t('routines'),
        });
    }

    menuItems.push(
        ...[
            {
                name: croutes.MY_WORKOUTS,
                text: t('workouts'),
            },
        ]
    );

    return menuItems;
}

function getUserProfileMenuItems(headerT: TFunction): React.ReactNode {
    return (
        <ListItem
            onClick={useCallback(async () => {
                await logout();
            }, [])}
        >
            <span className={'c-mi on-drawer'}>{headerT('logout')}</span>
        </ListItem>
    );
}

function getSettingsMenuItems(headerT: TFunction, permissions: string[]): React.ReactNode {
    const menuItems: NamedLinkMenuItem[] = [];

    menuItems.push({
        name: croutes.USER_ACCOUNT_SETTINGS,
        text: headerT('userAccountSettings'),
    });

    if (hasPermissions(permissions, bodyPartsRequiredPermissions)) {
        menuItems.push({
            name: croutes.BODYPARTS,
            text: headerT('bodyparts'),
        });
    }

    if (hasPermissions(permissions, categoriesRequiredPermissions)) {
        menuItems.push({
            name: croutes.CATEGORIES,
            text: headerT('categories'),
        });
    }

    if (hasPermissions(permissions, exercisesRequiredPermissions)) {
        menuItems.push({
            name: croutes.EXERCISES,
            text: headerT('exercises'),
        });
    }

    if (menuItems.length === 0) {
        return <></>;
    }

    return (
        <>
            {menuItems.map((mi, i) => (
                <ListItem key={i}>
                    <MenuListItem key={i} mi={mi} className={'on-drawer'} />
                </ListItem>
            ))}
        </>
    );
}
