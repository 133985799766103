import React, { memo, useCallback, useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import classNames from 'classnames';

import DraggableChildrenWrapper from '@/c/components/molecules/Modal/DraggableChildrenWrapper';
import useModalContext from '@/c/contexts/Modal/useModalContext';

import { Wrapper } from './Modal.styles';

type ElOrFunc = React.ReactElement | FuncBody;
type FuncBody = (props: { close: (reason?: any) => void }) => React.ReactElement;
type SlottedChildren = {
    closeBtn?: ElOrFunc;
    body: ElOrFunc;
};

export type ModalProps = {
    modalId?: string;
    children?: React.ReactNode | SlottedChildren | FuncBody;
    onClose?: (reason?: any) => void;
    barrierDismissible?: boolean;
    escDismissible?: boolean;
    inBodyCloseBtn?: boolean;
    draggable?: boolean;
    className?: string;
    modalClassName?: string;
    fullscreen?: boolean;
    isInHistory?: boolean;
};

const Modal: React.FC<ModalProps> = ({
    modalId,
    className,
    children,
    onClose,
    barrierDismissible,
    escDismissible,
    fullscreen,
    ...p
}) => {
    const modalContext = useModalContext();
    const [isOpen] = useState(true);

    const handleEscape = useCallback(
        (event) => {
            if (event.keyCode === 27 && modalContext!.isCurrent(modalId || '')) close();
        },
        [close]
    );

    useEffect(() => {
        const isDismissibleWithEsc = escDismissible === undefined ? true : escDismissible;
        if (isDismissibleWithEsc && isOpen && modalContext) {
            document.addEventListener('keydown', handleEscape, false);
        }
        return () => {
            document.removeEventListener('keydown', handleEscape, false);
        };
    }, [handleEscape, isOpen]);

    if (!isOpen) {
        return null;
    }

    const draggable = p.draggable ?? false;

    const body = (
        <>
            {(children as SlottedChildren).closeBtn && renderElOrFunc((children as SlottedChildren).closeBtn!)}
            <div className={classNames('v-modal', p.modalClassName)}>
                {p.inBodyCloseBtn && (
                    <IconButton
                        className={'in-b-close-btn'}
                        onClick={close}
                        style={{
                            backgroundColor: 'white',
                        }}
                        size={'small'}
                        color={'warning'}
                    >
                        <CancelIcon color={'warning'} />
                    </IconButton>
                )}
                {/*@ts-ignore*/}
                {renderElOrFunc((children as SlottedChildren).body ? (children as SlottedChildren).body : children)}
            </div>
        </>
    );

    return (
        <Wrapper
            className={classNames('v-modal-backdrop', className)}
            fullscreen={fullscreen}
            role="dialog"
            onClick={onClick}
            inBodyCloseBtn={p.inBodyCloseBtn}
        >
            {draggable ? <DraggableChildrenWrapper>{body}</DraggableChildrenWrapper> : body}
        </Wrapper>
    );

    function renderElOrFunc(elOrFunc: ElOrFunc): React.ReactElement {
        if (typeof elOrFunc === 'function') {
            const FCBody = elOrFunc as FuncBody;
            return <FCBody close={close} />;
        }

        return elOrFunc;
    }

    function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const target = event.target as HTMLElement;
        if (target.classList.contains('v-modal-backdrop')) {
            if (barrierDismissible !== false) {
                close();
            }
        }
    }

    function close() {
        if (p.isInHistory) {
            window.history.back();
        } else {
            modalContext!.destroyModal(modalId || '');
        }

        if (onClose) {
            onClose();
        }
    }
};
export default memo(Modal);
