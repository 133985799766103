import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';

import { HeaderWrapper, classes } from './Header.styles';

interface Props {
    startMenuItems?: React.ReactNode;
    fixedMenuItems: React.ReactNode;
    isDrawerMenuOpen?: boolean;
    handleToggleDrawer?: () => void;
    appBarExtra?: {
        zIndex?: number;
    };
}

export default function Header(p: Props): React.ReactElement {
    const isDrawerMenuOpen = p.isDrawerMenuOpen ?? false;

    return (
        <HeaderWrapper id={'header'}>
            <AppBar
                position="fixed"
                style={{
                    backgroundColor: 'black',
                    zIndex: p.appBarExtra?.zIndex ?? 2,
                }}
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: isDrawerMenuOpen,
                })}
            >
                <Toolbar>
                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        {p.startMenuItems}
                    </div>
                    <div>{p.fixedMenuItems}</div>
                    {p.handleToggleDrawer && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={p.handleToggleDrawer}
                            edge="start"
                            className={classNames(classes.menuButton, {
                                [classes.hide]: isDrawerMenuOpen,
                            })}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
        </HeaderWrapper>
    );
}
