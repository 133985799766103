import { useMutation, useQuery } from 'react-query';
import { MutationResultPair, QueryConfig, QueryResult } from 'react-query/types/core/types';

import api from '@/c/api';
import { ExerciseModel } from '@/c/components/types';
import { createModule } from '@/c/data/common';
import {
    CreateExerciseRequest,
    UpdateExerciseRequest,
    createExercise,
    deleteExercise,
    updateExercise,
} from '@/c/services/mutations';
import { getExercises } from '@/c/services/queries';

type T = ExerciseModel;
const ITEM_KEY = 'exercise';
const COLLECTION_KEY = 'exercises';

const { addItem, updateItem, deleteItem } = createModule<T>({
    itemKey: ITEM_KEY,
    collectionKey: COLLECTION_KEY,
});
export const updateExerciseInCache = updateItem;

export const useExercise = (id: string, queryConfig?: QueryConfig<T, Error>): QueryResult<T, Error> =>
    useQuery<T, Error>([ITEM_KEY, id], () => api.get(`/exercises/${id}`).then((res) => res.json()), queryConfig);

export const useExercises = (queryConfig?: QueryConfig<T[], Error>): QueryResult<T[], Error> =>
    useQuery<T[], Error>(COLLECTION_KEY, getExercises, queryConfig);

export const useCreateExerciseMutation = (): MutationResultPair<T, Error, CreateExerciseRequest, unknown> =>
    useMutation(createExercise, {
        onSuccess: addItem,
    });

export const useUpdateExerciseMutation = (): MutationResultPair<T, Error, UpdateExerciseRequest, unknown> =>
    useMutation(updateExercise, {
        onSuccess: updateItem,
    });

export const useDeleteExerciseMutation = (): MutationResultPair<Response, Error, string, unknown> =>
    useMutation(deleteExercise, {
        onSuccess: (data, id) => {
            data.status === 200 && deleteItem(id);
        },
    });
