import Jsenv from '../temp/env.json';

const Env = {
    version: Jsenv.APP_VERSION,
    environment: Jsenv.APP_ENV,
    assetsBasePath: Jsenv.CDN_URL,
    api: {
        endpoint: Jsenv.API_PUBLIC_ENDPOINT,
    },
    pushNotifications: {
        publicKey: Jsenv.WEB_NOTIFICATIONS_PUBLIC_KEY,
    },
    googleAnalytics: {
        measurementId: Jsenv._J_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    },
    ravenAppId: Jsenv._J_RAVEN_APP_ID,
};

export default Env;
