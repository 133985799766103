import { Breakpoints } from '../ThemeInterface';

const breakpoints: Breakpoints = {
    xs: {
        min: 0,
        max: 375,
        grid: {
            container: '100%',
            gutter: 0,
            blockSpacing: 40,
        },
    },
    sm: {
        min: 376,
        max: 507,
        grid: {
            container: '100%',
            gutter: 10,
            blockSpacing: 40,
        },
    },
    md: {
        min: 508,
        max: 767,
        grid: {
            container: 498,
            gutter: 30,
            blockSpacing: 60,
        },
    },
    lg: {
        min: 768,
        max: 1019,
        grid: {
            container: 747,
            gutter: 30,
            blockSpacing: 60,
        },
    },
    xl: {
        min: 1020,
        max: 8000,
        grid: {
            container: 1000,
            gutter: 30,
            blockSpacing: 60,
        },
    },
};

export default breakpoints;
