import React, { useState } from 'react';

import { Position, Rnd } from 'react-rnd';

import useWindowDimensions from '@/c/hooks/useWindowDimensions';

const DraggableChildrenWrapper: React.FC = (p) => {
    const windowDimensions = useWindowDimensions();
    const defaultModalSize = { width: windowDimensions.width * 0.7, height: windowDimensions.height * 0.7 };
    const [physics, setPhysics] = useState({
        ...defaultModalSize,
        ...getMiddleCoordinates(windowDimensions, defaultModalSize),
    });

    return (
        <Rnd
            size={{ width: physics.width, height: physics.height }}
            position={{ x: physics.x, y: physics.y }}
            onDragStop={(e, d) => {
                setPhysics((prevState) => ({
                    ...prevState,
                    ...{ x: d.x, y: d.y },
                }));
            }}
            onResize={(e, direction, ref, delta, position) => {
                setPhysics((prevState) => ({
                    ...prevState,
                    ...{
                        width: ref.offsetWidth,
                        height: ref.offsetHeight,
                    },
                    ...position,
                }));
            }}
        >
            {p.children}
        </Rnd>
    );
};
export default DraggableChildrenWrapper;

type Size = {
    width: number;
    height: number;
};

function getMiddleCoordinates(parentSize: Size, elSize: Size): Position {
    return { x: parentSize.width / 2 - elSize.width / 2, y: parentSize.height / 2 - elSize.height / 2 };
}
