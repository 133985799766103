import { UrlObject } from 'url';

import React, { memo } from 'react';

import classNames from 'classnames';

import { menuItemActiveClassname } from '@/c/components/templates/navigation-utils';
import { useRouter } from '@/core/router';

import Link from '../../../../core/Link/Link';

export type NamedLinkMenuItem = {
    id?: string;
    name: string | UrlObject;
    text: string;
};
export type TextMenuItem = {
    id?: string;
    text: string;
};
export type LinkMenuItem = {
    id?: string;
    url: string;
    text: string;
};
type ElRendered = () => React.ReactElement;
export type LiRendered = { hasLi?: boolean; render: ElRendered };
export type MenuItem = NamedLinkMenuItem | LinkMenuItem | TextMenuItem | ElRendered | LiRendered;

interface Props {
    mi: MenuItem;
    className?: string;
    onClick?: VoidFunction;
}

const MenuListItem: React.FC<Props> = ({ mi, className, ...o }) => {
    const router = useRouter();

    const linkClassNames = ['c-mi', menuItemActiveClassname(mi, router)];

    if ((mi as NamedLinkMenuItem).name) {
        return (
            <Link name={(mi as NamedLinkMenuItem).name} passHref={true}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a {...o} className={classNames(linkClassNames, className)}>
                    {(mi as NamedLinkMenuItem).text}
                </a>
            </Link>
        );
    }

    if ((mi as LinkMenuItem).url) {
        return (
            <Link name={''} href={(mi as LinkMenuItem).url} passHref={true}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a {...o} className={classNames(linkClassNames, className)}>
                    {(mi as LinkMenuItem).text}
                </a>
            </Link>
        );
    }

    if ((mi as TextMenuItem).text && !Object.prototype.hasOwnProperty.call(mi, 'name')) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="javascript:void(0)" {...o} className={classNames(linkClassNames, className)}>
                {(mi as TextMenuItem).text}
            </a>
        );
    }

    if (typeof mi === 'function') {
        return mi();
    }

    throw new Error(`Don't know how to render menuItem ${JSON.stringify(mi)}`);
};
export default memo(MenuListItem);
