import Env from '@/c/Env';

export default function registerServiceWorker(): void {
    const DEBUG = Env.environment === 'local';

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(function (swReg) {
                if (DEBUG) {
                    console.log('Service Worker is registered', swReg);
                }
            })
            .catch(function (error) {
                if (DEBUG) {
                    console.error('Service Worker Error', error);
                }
            });
    } else {
        if (DEBUG) {
            console.warn('serviceWorker is not supported in navigator');
        }
    }
}
