import { useContext } from 'react';

import { hasPermissions } from '@/c/auth/utils';
import { UserContext } from '@/c/contexts/UserContext';

export default function useHasPermission(requiredPermissions: string[] | string): boolean | undefined {
    const { data } = useContext(UserContext);
    if (data.loading || data.error) {
        return undefined;
    }

    const permissions = data.value!.config.permissions;

    return hasPermissions(
        permissions,
        Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]
    );
}
