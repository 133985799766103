import { ExerciseModel } from '@/c/components/types';

import api from '../api';
import {
    BodyPartDto,
    CategoryDto,
    GetRoutinesResponse,
    GetWorkoutsResponse,
    MetricDto,
    Routine,
    UserConfig,
    Workout,
} from '../types/api';

export const getBodyParts = (): Promise<BodyPartDto[]> =>
    api.get(`/bodyparts`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getCategories = (): Promise<CategoryDto[]> =>
    api.get(`/categories`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getMetrics = (): Promise<MetricDto[]> =>
    api.get(`/metrics`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getExercises = (): Promise<ExerciseModel[]> =>
    api.get(`/exercises`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getRoutines = (): Promise<GetRoutinesResponse> =>
    api.get(`/routines`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getRoutine = (id: string): Promise<Routine> =>
    api.get(`/routines/${id}`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getWorkouts = (): Promise<GetWorkoutsResponse> =>
    api.get(`/workouts`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getWorkout = (id: string): Promise<Workout> =>
    api.get(`/workouts/${id}`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        }
        throw new Error(await res.text());
    });

export const getUserConfig = (type: string): Promise<UserConfig> =>
    api.get(`/user/config?type=${type}`).then(async (res) => {
        if (res.status === 200) {
            return res.json();
        } else if (res.status === 404) {
            return Promise.resolve<UserConfig>({
                type: 'dashboards',
                value: '{}',
            });
        }
        throw new Error(await res.text());
    });
