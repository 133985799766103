import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Modal, { ModalProps } from '@/c/components/molecules/Modal/Modal';
import useModalContext from '@/c/contexts/Modal/useModalContext';

export type ConfirmDialogI18n = {
    yes: string;
    no: string;
};

interface Props extends ModalProps {
    i18n: ConfirmDialogI18n;
    title: string;
    open?: boolean;
    setOpen?: (v: boolean) => void;
    onConfirm: () => void;
    invertButtonsColor?: boolean;
}

const ConfirmDialog: React.FC<Props> = ({ i18n, ...p }) => {
    const modalContext = useModalContext();

    const { title, children, open, setOpen, onConfirm } = p;

    const insideModal = p.open === undefined;

    const closeDialog = () => {
        if (!insideModal) {
            setOpen!(false);
        } else {
            modalContext.destroyModal(p.modalId!);
        }
    };

    const invertButtonsColor = p.invertButtonsColor ?? false;

    const dialog = (
        <Dialog open={open ?? true} onClose={closeDialog} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeDialog} color={invertButtonsColor ? 'inherit' : 'warning'}>
                    {i18n.no}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                        onConfirm();
                    }}
                    color={invertButtonsColor ? 'warning' : 'primary'}
                >
                    {i18n.yes}
                </Button>
            </DialogActions>
        </Dialog>
    );

    if (insideModal) {
        return <Modal {...p}>{dialog}</Modal>;
    }

    return dialog;
};

export default ConfirmDialog;
