export enum Subject {
    BODY_PART = 'body_part',
    CATEGORY = 'category',
    EXERCISE = 'exercise',
    ROUTINE = 'routine',
}

export enum Action {
    MANAGE = 'manage',
    LIST = 'list',
    VIEW = 'view',
    UPDATE = 'update',
    CREATE = 'create',
    DELETE = 'delete',
}

export enum Scope {
    ALL = 'all',
    SELF = 'self',
}

export const EQ_ACTIONS = new Map([
    [Action.MANAGE, [Action.LIST, Action.VIEW, Action.UPDATE, Action.CREATE, Action.DELETE]],
]);
