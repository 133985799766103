import { getToken } from '@/c/http/api';
import { ensureIsClientSide } from '@/c/http/middlewares/helpers';

export default function beforeSendAddAuth(ri: RequestInit): RequestInit {
    ensureIsClientSide();
    return {
        ...ri,
        headers: {
            ...ri.headers,
            ...{
                Authorization: `Bearer ${getToken()}`,
            },
        },
    };
}
