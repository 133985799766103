import api from '@/c/api';
import HttpClientException from '@/c/http/HttpClientException';
import paths from '@/c/http/paths';

import { getJson } from '../utils/http';

export type RenewAccessTokenResponse = {
    accessToken: string;
    accessTokenExpiresAt: string;
};

export default class UsersAuthService {
    private static _instance: UsersAuthService;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}
    public static getInstance(): UsersAuthService {
        return this._instance || (this._instance = new this());
    }

    public async renewAccessToken(refreshToken: string): Promise<RenewAccessTokenResponse> {
        const response = await api.post(paths.renewAccessToken, {
            refreshToken: refreshToken,
        });

        if (response.status === 200) {
            return (await response.json()) as {
                accessToken: string;
                accessTokenExpiresAt: string;
            };
        }

        throw new HttpClientException(response.status, null);
    }

    public async updatePassword(payload: {
        actualPassword: string;
        newPassword: string;
        newPasswordConfirmation: string;
    }): Promise<{ status: 200 | 400 | 403; data: any }> {
        const response = await api.put(paths.updateUserPassword, payload);
        if (response.status === 200 || response.status === 400 || response.status === 403) {
            return {
                status: response.status,
                data: await getJson(response),
            };
        } else {
            throw new HttpClientException(response.status, null);
        }
    }
}
