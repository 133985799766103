import styled from 'styled-components';

import { fsStyle } from '../../templates/FullScreenMobileOnly';

export const Wrapper = styled.div<{
    fullscreen?: boolean;
    inBodyCloseBtn?: boolean;
}>`
    &.v-modal-fade-enter,
    &.v-modal-fade-leave-active {
        opacity: 0;
    }

    &.v-modal-fade-enter-active,
    &.v-modal-fade-leave-active {
        transition: opacity 0.4s ease;
    }
    &.v-modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .v-modal {
            ${(props) => props.inBodyCloseBtn && `position: relative;`};
            background-color: white;
            display: flex;
            flex-direction: column;
            box-shadow: 2px 2px 20px 1px;
            overflow-x: auto;
            overflow-y: auto;

            ${(props) => props.fullscreen === true && `${fsStyle}`};

            .in-b-close-btn {
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .v-modal-header {
            padding: 10px;
            text-align: center;
            color: black;
            font-size: 16px;
            font-weight: 500;
        }
    }
`;
