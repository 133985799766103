import React from 'react';

import { CircularProgress } from '@mui/material';

export default function BigLoader(): React.ReactElement {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '2rem',
            }}
        >
            <CircularProgress color="primary" size={'10rem'} thickness={1} />
        </div>
    );
}
