import React, { memo, useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';

import ConfirmDialog from '@/c/components/molecules/ConfirmDialog/ConfirmDialog';
import { RoutineWrapper } from '@/c/components/pages/RoutinesPage/RoutinesPage.style';
import { croutes } from '@/c/routes';
import useTranslation from '@/c/translations/useTranslation';
import { scopedT } from '@/c/translations/utils';
import { Routine } from '@/c/types/api';
import { LinkRenderer } from '@/core/linkRenderer';

interface Props {
    m: Routine;
    renderLink: LinkRenderer;
    onDuplicateClick?: (routine: Routine) => void;
    onEditClick?: (routineId: string) => void;
    onDeleteClick?: (routineId: string) => void;
}

const RoutineView: React.FC<Props> = (p) => {
    const { t } = useTranslation();
    const nt = scopedT(t, 'routines-page');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const handleClickSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    return (
        <RoutineWrapper>
            <span>
                {p.renderLink(
                    {
                        pathname: croutes.ROUTINE,
                        query: {
                            id: p.m.id,
                        },
                    },
                    <span>{p.m.name}</span>
                )}
                <IconButton onClick={handleClickSettings} size="large">
                    <SettingsIcon />
                </IconButton>
            </span>
            {renderMenu()}
            {p.onDeleteClick && (
                <ConfirmDialog
                    i18n={{
                        yes: t('yes'),
                        no: t('no'),
                    }}
                    title={nt('deleteRoutineDialog.title')}
                    open={openDeleteConfirm}
                    setOpen={setOpenDeleteConfirm}
                    onConfirm={() => p.onDeleteClick!(p.m.id)}
                    invertButtonsColor={true}
                >
                    {nt('deleteRoutineDialog.desc')}
                </ConfirmDialog>
            )}
        </RoutineWrapper>
    );

    function renderMenu() {
        const { t } = useTranslation();
        const nt = scopedT(t, 'routines-page');

        return (
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseSettings}>
                {p.onEditClick && (
                    <MenuItem
                        onClick={() => {
                            handleCloseSettings();
                            p.onEditClick!(p.m.id);
                        }}
                    >
                        {nt('editBtn')}
                    </MenuItem>
                )}
                {p.onDuplicateClick && (
                    <MenuItem
                        onClick={() => {
                            handleCloseSettings();
                            p.onDuplicateClick!(p.m);
                        }}
                    >
                        {nt('duplicateBtn')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        handleCloseSettings();
                    }}
                >
                    {p.renderLink(
                        {
                            pathname: croutes.WORKOUT_CREATE,
                            query: {
                                rid: p.m.id,
                            },
                        },
                        <span>{nt('startWorkoutFromRoutineBtn')}</span>
                    )}
                </MenuItem>
                {p.onDeleteClick && (
                    <MenuItem
                        onClick={() => {
                            handleCloseSettings();
                            setOpenDeleteConfirm(true);
                        }}
                    >
                        {nt('deleteBtn')}
                    </MenuItem>
                )}
            </Menu>
        );
    }
};
export default memo(RoutineView);
