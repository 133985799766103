import { routineEntryDataToRoutineEntry } from '@/c/data/mappers/workoutsMapper';
import { GetRoutinesResponse, Routine, RoutineFolder } from '@/c/types/api';

export type RoutinesAndFolders = {
    routines: Routine[];
    routineFolders: RoutineFolder[];
};

export function formRoutines(getRoutinesResponse: GetRoutinesResponse): Routine[] {
    const routines: Routine[] = [];

    for (const r of getRoutinesResponse.routines) {
        routines.push({
            id: r.id,
            name: r.name,
            entries: r.entries.map((e) =>
                routineEntryDataToRoutineEntry(e, {
                    exercises: getRoutinesResponse.exercises,
                    metrics: getRoutinesResponse.metrics,
                })
            ),
            fromUserId: r.fromUserId,
            folderId: r.folderId,
            unitsConfig: r.unitsConfig,
            created_at: r.created_at,
        });
    }

    return routines;
}
