import { i18n } from 'next-i18next';

import { DEFAULT_LOCALE, LOCALES } from '@/c/constants/locales';
import { LocalisedEntry, LocalisedString } from '@/c/types/common';

const map: Record<string, string> = {
    sq: LOCALES.ALBANIAN,
    en: LOCALES.ENGLISH,
};

function mapToLocale(locale: string): string {
    if (Object.prototype.hasOwnProperty.call(map, locale)) {
        return map[locale];
    }

    if (Object.values(map).includes(locale)) {
        return locale;
    }

    throw new Error(`Cannot map locale ${locale} to internal locale`);
}

export default function chooseInRightLocale<T = string>(localisedString: LocalisedEntry<T>): T {
    const currentLocale = (i18n && i18n.language) || DEFAULT_LOCALE;
    for (const l in localisedString) {
        if (l === currentLocale || mapToLocale(l) === currentLocale) {
            return localisedString[l];
        }
    }

    // console.error(
    //     `Cannot find any value for the given text ${JSON.stringify(
    //         localisedString
    //     )} in currentLocale ${currentLocale}, using first found`
    // );
    // TODO TMP
    // throw new Error(
    //     `Cannot find any value for the given text ${JSON.stringify(localisedString)} in currentLocale ${currentLocale}`
    // );

    return localisedString[Object.keys(localisedString)[0]];
}

export function chooseInRightLocaleArray<T>(input: T[], key: keyof T): any[] {
    return input.map((value) => ({
        ...value,
        [key]: chooseInRightLocale(value[key] as unknown as LocalisedString),
    }));
}

export function chooseInRightLocaleArrayKeys<T>(input: T[], ...keys: string[]): any[] {
    for (const key of keys) {
        input = chooseInRightLocaleArray(input, key as keyof T);
    }

    return input;
}

export function chooseRecursiveInRightLocale(input: Record<string, any>, lookupKey: string): void {
    for (const key in input) {
        const val = input[key];
        if (key === lookupKey) {
            if (typeof val === 'object') {
                input[lookupKey] = chooseInRightLocale(input[lookupKey]);
            }
        } else {
            if (typeof val === 'object' || Array.isArray(val)) {
                chooseRecursiveInRightLocale(val, lookupKey);
            }
        }
    }
}
