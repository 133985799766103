import { createContext } from 'react';

import { LoadableData } from '@/c/data/types';
import { MeUser } from '@/c/types/api';

type UserContextValueType = LoadableData<MeUser, Error | Response>;

export const UserContext = createContext<{
    data: UserContextValueType;
    setData: (data: UserContextValueType) => void;
}>({
    data: LoadableData.loading(),
    setData: () => {
        throw new Error('default setData should not be used');
    },
});
UserContext.displayName = 'UserContext';
