import { i18n } from 'next-i18next';

import { DEFAULT_LOCALE } from '@/c/constants/locales';

export function getCurrentLocale(): string {
    const isClientSide = typeof window !== 'undefined';
    if (!isClientSide) {
        throw new Error(
            `Don't call getCurrentLocale from server side. It uses i18n that is initialised in client only`
        );
    }
    return (i18n && i18n.language) || DEFAULT_LOCALE;
}
