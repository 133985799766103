import React from 'react';

import PermissionsGate from '@/c/auth/PermissionsGate';

export default function withPermissionsGate<P>(
    WrappedComponent: React.ComponentType<P>,
    requiredPermissions: string | string[]
): (props: P) => null | JSX.Element {
    const withPermissionsGate = (props: P) => (
        <PermissionsGate
            requiredPermissions={Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]}
        >
            <WrappedComponent {...props} />
        </PermissionsGate>
    );

    withPermissionsGate.WrappedComponent = WrappedComponent;

    return withPermissionsGate;
}
