import { LayoutProps, ViewManager } from './types';

export default function calculateViewManager(meta: LayoutProps): ViewManager {
    const showHeader = meta.header !== undefined ? meta.header : true;
    const r: ViewManager = {
        header: showHeader,
        headerNavigation: showHeader && meta.headerNavigation !== false,
        customMain: meta.customMain === true,
        bottomNavigation: meta.mobileBottomNavigation !== false,
        footer: meta.footer || false,
    };

    return r;
}
