export default class AssetsResolver {
    private readonly versionedAssetsFolder: string | null;

    constructor(private readonly basePath: string, private readonly releaseVersion: string | null) {
        this.basePath = basePath.replace(/\/+$/, '');
        this.versionedAssetsFolder = releaseVersion ? `assets/${releaseVersion}` : null;
    }

    image(relativePath: string): string {
        relativePath = relativePath.replace(/\/+$/, '');
        return `${this.basePath}/images/${relativePath}`;
    }

    sound(relativePath: string): string {
        relativePath = relativePath.replace(/\/+$/, '');
        return `${this.basePath}/sounds/${relativePath}`;
    }

    cAsset(relativePath: string): string {
        relativePath = relativePath.replace(/\/+$/, '');
        if (this.versionedAssetsFolder === null) {
            return `${this.basePath}/${relativePath}`;
        }
        return `${this.basePath}/${this.versionedAssetsFolder}/${relativePath}`;
    }
}
