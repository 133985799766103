import update from 'immutability-helper';
import { QueryKey, queryCache } from 'react-query';

export function updateState<T>(key: QueryKey, fn: (prevState: T) => T): void {
    const prevState = queryCache.getQueryData<T>(key);
    if (!prevState) {
        console.error(`Cannot fetch data with key ${JSON.stringify(key)} from react-query cache`);
        return;
    }
    queryCache.setQueryData<T>(key, fn(prevState));
}

type Identifiable = {
    id: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createModule<T extends Identifiable>(c: { collectionKey: string; itemKey: string }) {
    const addItem = (item: T): void => {
        updateState<T>([c.itemKey, item.id], () => item);
        updateState<T[]>(c.collectionKey, (prevState) => {
            return [item as unknown as T, ...prevState];
        });
    };

    const deleteItem = (id: string): void => {
        updateState<T[]>(c.collectionKey, (prevState) => {
            return prevState.filter((el) => el.id !== id);
        });
    };

    const updateItem = (data: T): void => {
        updateState<T>([c.itemKey, data.id], () => data);
        updateState<T[]>(c.collectionKey, (prevState) => {
            const index = prevState.findIndex((el) => el.id.toString() === data.id);
            if (index === -1) {
                return prevState;
            }
            return update(prevState, {
                [index]: {
                    $set: {
                        ...prevState[index],
                        ...(data as unknown as T),
                    },
                },
            });
        });
    };

    return {
        addItem,
        deleteItem,
        updateItem,
    };
}
