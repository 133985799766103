import React, { useContext } from 'react';

import { Button, Typography } from '@mui/material';

import RoutineView from '@/c/components/pages/RoutinesPage/RoutineView';
import { UserContext } from '@/c/contexts/UserContext';
import useTranslation from '@/c/translations/useTranslation';
import { scopedT } from '@/c/translations/utils';
import { Routine } from '@/c/types/api';
import { LinkRenderer } from '@/core/linkRenderer';

import { RoutinesList, Wrapper } from './RoutinesPage.style';

const DEBUG = false;

interface Props {
    routines: Routine[];
    onCreateClick: () => void;
    onEditClick: (routineId: string) => void;
    onDuplicateClick: (routine: Routine) => void;
    onDeleteClick: (routineId: string) => void;
    renderLink: LinkRenderer;
}

export default function RoutinesPage(p: Props): React.ReactElement<Props> {
    if (DEBUG) {
        console.log('RoutinesPage', p.routines);
    }

    const { t } = useTranslation();
    const nt = scopedT(t, 'routines-page');

    const { data } = useContext(UserContext);
    const loggedUserId = data.value?.id;

    const myRoutines = [];
    const platformRoutines = [];
    const otherUsersRoutines = [];

    for (const routine of p.routines) {
        if (routine.fromUserId === loggedUserId) {
            myRoutines.push(routine);
        } else if (routine.fromUserId === null) {
            platformRoutines.push(routine);
        } else {
            otherUsersRoutines.push(routine);
        }
    }

    return (
        <Wrapper>
            <Button
                style={{ marginBottom: '1rem' }}
                variant={'contained'}
                onClick={() => {
                    p.onCreateClick();
                }}
            >
                {nt('createRoutineBtn')}
            </Button>
            {myRoutines.length > 0 && (
                <>
                    <Typography variant={'h6'}>{nt('myRoutines')}</Typography>
                    <RoutinesList>
                        {myRoutines.map((e) => (
                            <RoutineView
                                key={e.id}
                                m={e}
                                renderLink={p.renderLink}
                                onEditClick={p.onEditClick}
                                onDuplicateClick={p.onDuplicateClick}
                                onDeleteClick={p.onDeleteClick}
                            />
                        ))}
                    </RoutinesList>
                </>
            )}
            {platformRoutines.length > 0 && (
                <>
                    <Typography variant={'h6'}>{nt('platformRoutines')}</Typography>
                    <RoutinesList>
                        {platformRoutines.map((e) => (
                            <RoutineView
                                key={e.id}
                                m={e}
                                renderLink={p.renderLink}
                                onDuplicateClick={p.onDuplicateClick}
                            />
                        ))}
                    </RoutinesList>
                </>
            )}
            {otherUsersRoutines.length > 0 && (
                <>
                    <Typography variant={'h6'}>{nt('otherUsersRoutines')}</Typography>
                    <RoutinesList>
                        {otherUsersRoutines.map((e) => (
                            <RoutineView
                                key={e.id}
                                m={e}
                                renderLink={p.renderLink}
                                onDuplicateClick={p.onDuplicateClick}
                            />
                        ))}
                    </RoutinesList>
                </>
            )}
        </Wrapper>
    );
}
